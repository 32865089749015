<template>
  <el-main class="rh-medtable">
    <h1>Tuteurs</h1>
    <app-table
      tablenom="medtuteurs"
      :cols="colonnes"
      :items="tuteurs"
      :loadstatus="tuteursLoadStatus"
      :deletestatus="tuteurDeleteStatus"
      :formroutes="tutFormRoutes"
      :actions="tutActions"
      :ismeddetail="true"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: this.$route.params.idmed, // récupère le id du médecin dans l'url
      tutFormRoutes: {
        edit: {
          route: "med_tuteurs_edit",
          params: { idmed: "id_pupille", idtutorat: "id" },
        },
        create: {
          route: "med_tuteurs_create",
          params: { idmed: this.$route.params.idmed },
        },
      },
    };
  },
  computed: {
    tuteursLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.tut.tuteursLoadStatus;
    },
    tuteurDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.tut.tuteurDeleteStatus;
    },
    tuteurs() {
      // n'affiche que les tuteurs du médecin sélectionné
      return this.$store.state.tut.tuteurs.filter(
        (t) => t.id_pupille == this.idMed
      );
    },
    colonnes() {
      // n'affiche pas la colonne 'du nom du pupille
      return this.$store.state.tut.colonnes.filter(
        (col) => col.prop != "pupille"
      );
    },
    tutActions() {
      return this.$store.state.tut.actions;
    },
  },
};
</script>
